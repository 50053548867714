import React from "react";
import {VraagSolvabiliteitsAttestAanButton} from "./VraagSolvabiliteitsAttestAanButton";


export function Hero() {
  return (
    <section id="hero" className="relative">
      <Background/>
      <div className="flex items-center container relative z-20 min-h-screen lg:h-screen lg:block">
        <div className="flex flex-col justify-center h-full lg:flex-row lg:justify-between gap-8">
          <div
            className="flex flex-col order-2 justify-center pb-4 h-full lg:pb-0 lg:w-96 lg:justify-center lg:order-none">
            <Body></Body>
          </div>
          <div className="flex flex-1 order-1 items-center lg:order-none lg:h-auto">
            <PromoVideo/>
          </div>
        </div>
      </div>
    </section>
  );
}


function Body() {
  return (
    <div
      className="flex flex-col justify-center items-center h-1/2 text-center lg:items-start lg:text-left">
      <h1 className="h1">
        Bewijs je solvabiliteit
      </h1>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Nog nooit is het zo makkelijk geweest om uw eigen solvabiliteit aan te tonen en het
        vertrouwen te krijgen
        van kredietverstrekkers of toekomstige zakenpartners.
      </p>
      <VraagSolvabiliteitsAttestAanButton/>
    </div>
  )
}

function PromoVideo() {
  return (
    <div className="overflow-hidden relative pt-56 w-full h-full">
      <iframe
        title="solvattest promo video"
        className="absolute top-0 left-0 w-full h-full border-0"
        src="https://player.vimeo.com/video/617301402?h=f45ff58727"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}

function Background() {
  return (
    <div
      className="absolute w-full min-h-full lg:h-full bg-left bg-no-repeat bg-header-desktop bg-custom-header-size lg:block"/>
  )
}


import React from "react";
import {Gdw} from "./Gdw";
import JsxParser from "react-jsx-parser";

export function What() {
  return (
    <section id="what" className="py-14 bg-neutral-light-grayish-blue lg:py-24">
      <div className="container text-center lg:text-left">
        <div className="grid mb-12 lg:grid-cols-2 lg:mb-16">
          <div className="col-span-1">
            <h2 className="pb-5 text-3xl lg:text-4xl text-primary-dark-green">
              Wat is solvattest?
            </h2>
            <p className="text-sm leading-loose text-neutral-grayish-blue lg:text-base">
              Wil jij graag aantonen dat je een financieel stabiele huurder, een betrouwbare (zaken)partner of solide
              ontlener bent? Solvattest kan je hierbij helpen!
            </p>
            <p className="mt-4 text-sm leading-loose text-neutral-grayish-blue lg:text-base">
              Door aan te tonen dat je financieel betrouwbaar en schuldenvrij bent, differentieer je je van andere
              kandidaten.
            </p>
            <p className="mt-4 text-sm leading-loose text-neutral-grayish-blue lg:text-base">
              Solvattest is een gebruiksvriendelijke applicatie die de gebruiker toestaat om van zichzelf een
              betrouwbaar solvabiliteitsonderzoek te laten voeren door gerechtsdeurwaarderskantoor <Gdw/>.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-9 lg:gap-6 lg:grid-cols-4">
          {motivationItems.map(({iconPath, subtitle, title}) => (
            <div key={title} className="justify-center">
              <div className="flex justify-center lg:justify-start">
                <img src={iconPath} alt=""/>
              </div>

              <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-6 lg:text-xl lg:font-bold">
                {title}
              </h2>
              <div className="text-sm font-light leading-5 break-words text-neutral-grayish-blue lg:text-base">
                <JsxParser components={{Gdw}} jsx={subtitle}/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

const motivationItems: { subtitle: string; iconPath: string; title: string }[] = [
  {
    iconPath: '/icons/icon-online.svg',
    title: 'Veilig',
    subtitle:
      'Via gerechtsdeurwaarderskantoor <Gdw/> worden Uw gegevens op een betrouwbare manier behandeld.',
  },
  {
    iconPath: '/icons/icon-budgeting.svg',
    title: 'Snel',
    subtitle:
      'Kies zelf de termijn waarbinnen u het attest afgeleverd wenst te zien.',
  },
  {
    iconPath: '/icons/icon-onboarding.svg',
    title: 'Gebruiksvriendelijk',
    subtitle:
      'Vraag jouw solvabiliteitsattest aan in enkele klikken.',
  },
  {
    iconPath: '/icons/icon-api.svg',
    title: 'GDPR-Conform',
    subtitle:
      'Door de integratie met itsme is het enkel mogelijk om voor uzelf een solvabiliteitsattest aan te vragen.',
  },
];

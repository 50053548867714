import {Navbar} from "../components/Navbar";
import React from "react";
import Footer from "../components/Footer";

export function CookiePolicy() {
  return (
    <div className="flex flex-col justify-center min-h-screen mt-32">
      <Navbar/>
      <div className="container relative min-h-screen lg:block pb-10 policy">

        <h1 id="i-introductie">Introductie</h1>
        <p>De Website <a href="http://www.solvattest.be/">www.solvattest.be</a> (hierna de &quot;Website&quot;) maakt
          gebruik van cookies en daarmee vergelijkbare technologieën. Deze cookieverklaring
          (hierna &quot;Cookieverklaring&quot;) wil u als bezoeker informeren over het gebruik hiervan.</p>
        <p>Naast deze Cookieverklaring stelt GDW-Gent ook een Privacyverklaring ter beschikking, waarvan deze
          Cookieverklaring integraal deel uitmaakt en die u hier kunt raadplegen. Voor de verzameling en verdere
          verwerking van uw persoonsgegevens in het algemeen en uw rechten daaromtrent, verwijzen wij naar deze
          Privacyverklaring. Deze Cookieverklaring gaat nader in op de concrete manier waarop wij cookies gebruiken op
          onze Website.</p>
        <p>Wij wensen er u als bezoeker op te wijzen dat u niet alle cookies moet aanvaarden. Het aanvaarden van het
          gebruik van cookies die niet strikt noodzakelijk zijn, maakt namelijk geen voorwaarde uit voor het bezoeken
          van
          onze Website. Bij het weigeren ervan bestaat de mogelijkheid wel dat bepaalde functies van onze Website en
          aanvullende diensten niet optimaal functioneren.</p>
        <p>Indien u nog vragen zou hebben omtrent onze Cookieverklaring, kan u steeds contact opnemen met ons via de
          volgende gegevens:</p>
        <ul>
          <li>via e-mail: privacy@gdw-gent.be</li>
          <li>via brief: GDW-Gent BV BVBA</li>
        </ul>
        <p>t.a.v. de DPO</p>
        <p>Kortrijksesteenweg 1038</p>
        <p>9051 Gent/ Sint-Denijs-Westrem</p>
        <p>België</p>
        <h1 id="ii-wat-zijn-cookies-">Wat zijn cookies?</h1>
        <p>Cookies zijn kleine gegevens- of tekstbestanden die op uw computer of mobiele apparaat zijn geïnstalleerd
          wanneer u een website bezoekt of een (mobiele) toepassing gebruikt. Het cookiebestand bevat een unieke code
          waarmee uw browser herkend kan worden door de website of webtoepassing tijdens het bezoek aan de online
          service
          of tijdens opeenvolgende, herhaalde bezoeken.</p>
        <p>Cookies kunnen worden geplaatst door de server van de website of applicatie die u bezoekt (&quot;first-party
          cookies&quot;), maar ook door servers van derden (&quot;third-party cookies&quot;) die al dan niet met deze
          website of applicatie samenwerken. Third-party cookies zorgen ervoor dat bepaalde gegevens door uw bezoek aan
          of
          uw gebruik van onze Website doorgezonden worden aan de derde partij die de cookies geplaatst heeft.</p>
        <p>Cookies maken over het algemeen de interactie tussen de bezoeker en de website of applicatie gemakkelijker en
          sneller en helpen de bezoeker om te navigeren tussen de verschillende delen van een website of applicatie.</p>
        <p>Ze bevatten informatie zoals bijvoorbeeld de taalvoorkeur van de bezoeker, zodat hij die informatie niet
          opnieuw moet ingeven bij een volgend bezoek aan dezelfde website. Sommige cookies zorgen ervoor dat een
          website
          grafisch netjes verschijnt, andere dat een Websiteapplicatie correct werkt.</p>
        <h1 id="iii-hoelang-blijven-cookies-bestaan-">Hoelang blijven cookies bestaan? <strong> </strong></h1>
        <p>Op basis van de bestaansduur van cookies maken wij een onderscheid tussen tijdelijke en permanente
          cookies.</p>
        <p> Tijdelijke cookies worden tijdelijk opgeslagen in je browser of (mobiele) applicatie. Dat betekent dat die
          cookies verwijderd worden zodra je je browser of (mobiele) applicatie hebt gesloten.</p>
        <p> Permanente cookies blijven op je computer of mobiel apparaat staan, ook al heb je je browser of (mobiele)
          applicatie gesloten. Dankzij permanente cookies kunnen we je herkennen wanneer je onze Website later opnieuw
          bezoekt. Permanente cookies blijven op je apparaat staan tot een vooraf automatisch bepaalde einddatum bereikt
          is, tot er een nieuwe cookie geïnstalleerd wordt, of tot jij ze verwijdert via de instellingen van je browser,
          (mobiele) applicatie of mobiel apparaat.</p>
        <h1 id="iv-waarvoor-gebruiken-wij-cookies-">Waarvoor gebruiken wij cookies?</h1>
        <p>GDW-Gent onderscheidt op haar Website de volgende soorten cookies: (1) strikt noodzakelijke cookies, (2)
          analytische cookies en (3) advertentie-, tracking- en sociale mediacookies.</p>
        <p>Hieronder verduidelijken wij waarvoor elk van deze (soorten) cookies dienen.</p>
        <p>Alvorens wij enige cookies op uw toestel plaatsen, vragen wij – al naargelang het geval – om uw voorafgaande
          toestemming via onze cookiebanner. U kan uw toestemming voor het gebruik van deze cookies verlenen door te
          klikken op de aanvaardingsknop die in de cookiebanner geïntegreerd is. U beschikt bovendien over de
          mogelijkheid
          om per categorie cookies, zoals hieronder omschreven, al dan niet uw toestemming te verlenen. Dit kan u doen
          door in de cookiebanner op &#39;Instellingen&#39; te klikken, waarlangs u doorverwezen wordt naar het
          keuzemenu
          voor uw cookievoorkeuren. U kan uw toestemming bovendien op elk moment terug intrekken, zoals verduidelijkt
          onder punt V.</p>
        <h2 id="1-strikt-noodzakelijke-cookies">Strikt noodzakelijke cookies</h2>
        <p>Onder strikt noodzakelijke cookies vallen enerzijds technisch essentiële cookies en anderzijds functionele
          cookies. Technisch essentiële cookies zijn om puur technische redenen noodzakelijk om de communicatie tussen
          uw
          toestel en onze Website mogelijk te maken. Functionele cookies zorgen er dan weer voor dat de
          basisfunctionaliteiten van onze Website naar behoren werken (bv. opslaan van uw voorkeuren zoals taal,
          locatie,
          etc.). Deze cookies worden pas geplaatst nadat u als bezoeker een bepaalde handeling heeft ondernomen op onze
          Website (bv. op de aanvaardingsknop van de cookiebanner klikken).</p>
        <p>U kan het gebruik van technisch essentiële en functionele cookies niet weigeren omdat ze noodzakelijk zijn
          voor
          de beveiliging en de goede werking van de basisfunctionaliteiten van onze Website. Hoe dan ook worden deze
          cookies slechts op uw toestel bewaard om de hierboven vermelde functies uit te oefenen, waardoor de impact op
          uw
          privacy gering is.</p>
        <p>Hieronder vindt u een lijst met de cookies die onze site gebruikt binnen deze categorie:</p>
        <table>
          <thead>
          <tr>
            <th>Naam</th>
            <th>Vervaldag</th>
            <th>Domein</th>
            <th>Doel</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          </tbody>
        </table>
        <h2 id="2-analytische-cookies">Analytische cookies</h2>
        <p>Analytische cookies stellen ons in staat om meer te weten te komen over de manier waarop u als bezoeker
          omgaat
          met en hoe u reageert op de inhoud van onze Website. Op die manier kunnen wij onze Websites nog
          aantrekkelijker
          maken. De informatie in kwestie wordt gebruikt om op totaalniveau statistieken over het gebruik van onze
          Website
          te creëren en te analyseren. Deze statistieken geven ons inzicht in hoe vaak onze webpagina bezocht wordt en
          waar bezoekers over het algemeen de meeste tijd doorbrengen. Hierdoor zijn we in staat de structuur, navigatie
          en inhoud van de Website zo gebruiksvriendelijk mogelijk te maken.</p>
        <p>Hieronder vindt u een lijst met de cookies die onze Website gebruikt binnen deze categorie:</p>
        <table>
          <thead>
          <tr>
            <th>Naam</th>
            <th>Vervaldag</th>
            <th>Domein</th>
            <th>Doel</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          </tbody>
        </table>
        <h2 id="3-advertentie-tracking-en-sociale-mediacookies">Advertentie-, tracking- en sociale mediacookies</h2>
        <p>Advertentie-, tracking- en sociale mediacookies betreffen voornamelijk cookies met het oog op het weergeven
          van
          advertenties, het implementeren van functies op onze Website geboden door sociale media, en het meten van hoe
          vaak deze advertenties en functies respectievelijk weergegeven en gebruikt worden.</p>
        <p>Hieronder vindt u een lijst met de cookies die onze Website gebruiken binnen deze categorieën:</p>
        <table>
          <thead>
          <tr>
            <th>Naam</th>
            <th>Vervaldag</th>
            <th>Domein</th>
            <th>Doel</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          </tbody>
        </table>
        <h1 id="v-hoe-cookievoorkeuren-beheren-of-verwijderen-">Hoe cookievoorkeuren beheren of verwijderen?</h1>
        <p>Hieronder wordt verduidelijkt hoe u uw cookievoorkeuren kan beheren. Aangezien de strikt noodzakelijke
          cookies
          noodzakelijk zijn voor het functioneren van onze Website, kunnen deze niet uitgeschakeld worden.</p>
        <h3 id="a-via-de-website">A.Via de Website</h3>
        <p>U kan op elk moment uw cookievoorkeuren aanpassen door [aan te vullen].</p>
        <h3 id="b-via-uw-browser">B.Via uw browser</h3>
        <p>U kan op elk moment uw cookievoorkeuren aanpassen via uw browser. Op deze manier kunt u zelf aangeven of
          bepaalde cookies geplaatst mogen worden en kan u de browser instellen om een bericht weer te geven als er een
          cookie wordt geplaatst. Daarnaast kan u cookies ook handmatig verwijderen via de optie die u toelaat om uw
          browsegeschiedenis te wissen. Meer informatie hierover kan u op onderstaande pagina&#39;s terugvinden,
          afhankelijk van welke browser u gebruikt:</p>
        <p>
          <p>
            <a
              href="https://support.microsoft.com/nl-nl/help/17442/windows-internet-explorer-delete-manage-cookies">
              <strong>Cookievoorkeuren Internet Explorer</strong>
            </a>
          </p>
          <p>
            <a
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies">
              <strong>Cookievoorkeuren Firefox</strong></a>
          </p>
          <p>
            <a href="https://support.google.com/chrome/answer/95647?hl=en"><strong>Cookievoorkeuren Chrome</strong></a>
          </p>
          <p>
            <a href="https://support.apple.com/nl-be/HT201265"><strong>Cookievoorkeuren Safari</strong></a></p></p>
        <p>Hou er wel rekening mee dat bepaalde grafische elementen misschien niet mooi zullen verschijnen, of dat u
          bepaalde applicaties niet zult kunnen gebruiken als u cookies uitschakelt.</p>
        <h1 id="vi-wijzigingen">Wijzigingen</h1>
        <p>Wij kunnen eenzijdig beslissen om veranderingen aan deze Cookieverklaring aan te brengen. In dat geval treedt
          de nieuwe versie onmiddellijk in werking. De meest recente versie is te allen tijde beschikbaar op onze
          Website.
          Indien wij hierbij nieuwe cookies hebben geïmplementeerd op onze Website, zullen wij indien vereist opnieuw om
          uw toestemming vragen. De datum waarop deze Privacyverklaring voor het laatst gewijzigd is geweest, is
          onderaan
          terug te vinden.</p>
        <p><strong>Laatst gewijzigd op: 1 oktober 2021.</strong></p>


      </div>
      <Footer/>
    </div>


  );
}

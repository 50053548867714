import React, {FormEvent, useEffect, useState} from "react";
import {Gdw} from "../components/Gdw";
import {AppConfig} from "../appConfig";
import {FieldErrors, FieldValues, useForm, UseFormRegisterReturn} from "react-hook-form";
import {useLocation} from "react-router-dom";
import {FetchDataState, FetchingNRNFailed} from "./FetchingNRNFailed";

export const productOptions: ProductOptionType[] = [
  {value: "STANDARD", text: "Binnen\n2 werkdagen", price: 60.5, selected: true},
]

type ProductOptionType = { value: string, text: string, price: number, selected: boolean };

export function Order() {
  const config = AppConfig.getInstance();

  let urlSearchParams = new URLSearchParams(useLocation().search);
  const code = urlSearchParams.get("code");

  const {register, formState: {errors}, handleSubmit, setValue} = useForm({mode: "onBlur"});

  const onBeforeSubmit = (e: any) => {
    e.preventDefault();
    setValue('name', name);
    setValue('email', email);
    handleSubmit(onSubmit)();
  };

  const [state, setLoadingState] = useState(FetchDataState.LOADING)

  const [orderId, setOrderId] = useState("");
  const [nrn, setNrn] = useState("XX-XXXXX-XX");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [purpose, setPurpose] = useState("");
  const [deliverySpeed, setDeliverySpeed] = useState("STANDARD");
  const [thirdPartyPermission, setThirdPartyPermission] = useState(false);
  const [emailThirdParty, setEmailThirdParty] = useState("");
  const [invoiceWanted, setInvoiceWanted] = useState(false);
  const [billingInformation, setbillingInformation] = useState("");

  useEffect(() => {
    (async () => {
      if (code) {
        let body: { nrn: string, orderId: string };
        try {
          let url = `${config.apiBaseUrl}/itsme?code=${code}`
          let response: Response = await fetch(url)
          body = await response.json();
          setNrn(body.nrn);
          setOrderId(body.orderId);
          setLoadingState(FetchDataState.SUCCESSFUL);
        } catch (e) {
          console.log(e);
          setLoadingState(FetchDataState.FAILED);
        }
      } else {
        console.error("Code not found");
        setLoadingState(FetchDataState.FAILED);
      }
    })();
  }, [code, config]);

  const onSubmit = () => {
    (async () => {
      let body = {
        orderId, deliverySpeed, name, email, thirdPartyPermission,
        emailThirdParty, invoiceWanted, billingInformation, purpose,
      };
      try {
        let url = `${config.apiBaseUrl}/payment`
        let result: Response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {"Content-Type": "application/json"},
          redirect: 'follow'
        },)
        let json = await result.json();
        window.location = json.checkout_url
      } catch (e) {
        console.error(e);
      }
    })();
  }
  const updateSelection = (event: FormEvent) => {
    setDeliverySpeed((event.target as HTMLInputElement).value)
  };
  const handleInvoiceWantedCheckboxChange = (event: FormEvent) => {
    setInvoiceWanted((event.target as HTMLInputElement).checked)
  }
  const handleThirdPartyCheckboxChange = (event: FormEvent) => {
    setThirdPartyPermission((event.target as HTMLInputElement).checked)
  }
  const handleBillingInformationChange = (event: FormEvent) => {
    setbillingInformation((event.target as HTMLInputElement).value)
  }

  return (
    <div
      className="bg-header-desktop bg-custom-header-size min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full mb-8">
        <div className="bg-white shadow-md rounded px-4 lg:px-8 pt-6 pb-8 mb-4 max-w-md mx-auto sm:max-w-xl">
          <div hidden={state !== FetchDataState.LOADING}>
            <p className="text-center my-4">
              Bezig met het ophalen van jouw rijksregisternummer
            </p>
            <p className="text-center my-4">
              een ogenblik geduld ...
            </p>
          </div>
          <FetchingNRNFailed state={state}/>
          <form
            hidden={state !== FetchDataState.SUCCESSFUL}
            onSubmit={onBeforeSubmit}>

            <NRNField value={nrn}/>

            <NameField useFormRegisterReturn={register("name", {required: true})}
                       onChange={(e) => setName(e.target.value)}
                       value={name}
                       errors={errors}/>

            <EmailField useFormRegisterReturn={register("email", {required: true})}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        errors={errors}/>

            <PurposeField onChange={(e) => setPurpose(e.target.value)}
                          value={purpose}/>

            <ThirdParty checked={thirdPartyPermission}
                        onChange={handleThirdPartyCheckboxChange}
                        onChange1={(e) => setEmailThirdParty(e.target.value)}
                        value={emailThirdParty}/>

            <DeliverySpeedSelector onChange={updateSelection}
                                   selected={deliverySpeed}/>


            <Billing checked={invoiceWanted}
                     onInvoiceWantedChange={handleInvoiceWantedCheckboxChange}
                     onBillingInfoChange={handleBillingInformationChange}/>

            <SubmitButton/>
          </form>
        </div>
        <div className="text-center text-primary-green-olive text-xs">
          <span>
            &copy;2021 <Gdw/>. Alle rechten voorbehouden
          </span>
          <span className="text-primary-dark mx-1">|</span>
          <a className="hover:text-primary-green-apple"
             href="/privacy-policy"
          > Privacy Policy
          </a>
          <span className="text-primary-dark mx-1">|</span>
          <a className="hover:text-primary-green-apple"
             href="/cookie-policy"
          > Cookie Policy
          </a>
        </div>
      </div>
    </div>

  );
}

function ProductOption({value, text, price, selected}: ProductOptionType) {
  return <div>
    <label htmlFor={value}>
      <div
        className={`flex flex-col items-center border-2 p-2 rounded-md ${selected ? "border-primary-green-olive" : "border-gray-100"}`}>
        <p className={`font-medium text-xl ${selected ? "text-primary-green-olive" : "text-gray-600"}`}>
          <span>€ </span>
          <span>{price}</span>
        </p>
        <p className="text-xs text-center whitespace-pre">{text}</p>
      </div>
    </label>

    <input id={value} value={value} name={"products"} type="radio" hidden={true}/>
  </div>
}

function NRNField(props: { value: string }) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="name"
    >
      Rijksregisternummer
    </label>
    <input
      disabled
      type="text"
      value={props.value}
      className="bg-gray-300 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4
                        my-4 block w-full appearance-none leading-normal disabled:opacity-50"
    />
  </div>;
}

function NameField(props: { useFormRegisterReturn: UseFormRegisterReturn, onChange: (e: any) => void, value: string, errors: FieldErrors<FieldValues> }) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="name"
    >
      Volledige naam
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="text"
      id="name"
      name="name"
      onChange={props.onChange}
      value={props.value}
      placeholder="Voornaam achternaam"
    />
    {props.errors.name?.type === "required" &&
      <span className="text-red-400 text-sm">Naam is verplicht</span>}
  </div>;
}

function EmailField(props: { useFormRegisterReturn: UseFormRegisterReturn, onChange: (e: any) => void, value: string, errors: FieldErrors<FieldValues> }) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="email"
    >
      Email
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="email"
      id="email"
      name="email"
      onChange={props.onChange}
      value={props.value}
      placeholder="jane@example.com"
    />
    {props.errors.email?.type === "required" &&
      <span className="text-red-400 text-sm">Email is verplicht</span>}
  </div>;
}

function PurposeField(props: { onChange: (e: any) => void, value: string }) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="purpose"
    >
      Doel van het attest
    </label>
    <textarea
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      id="purpose"
      name="purpose"
      onChange={props.onChange}
      value={props.value}
      placeholder="Ik wil een krediet aanvragen bij de bank van €50.000"
    />
    <p className="text-xs purpose__example text-gray-600 mt-2">
      <span className="ml-6 block">Ik wil een krediet aanvragen van €50.000 bij de bank</span>
      <span className="ml-6 block">Ik wil een huis huren voor €850 per maand</span>
    </p>
  </div>;
}

function ThirdParty(props: { checked: boolean, onChange: (event: React.FormEvent) => void, onChange1: (e: any) => void, value: string }) {
  return <>
    <div className="mt-4">
      <label className="inline-flex items-center">
        <input type="checkbox"
               className="form-checkbox text-green-500 h-4 w-4"
               checked={props.checked}
               onChange={props.onChange}/>
        <span className="ml-2">Attest <span
          className="font-medium">ook</span> afleveren aan een derde partij</span>
      </label>
    </div>

    <div className="" hidden={!props.checked}>
      <span className="block text-gray-700 text-sm ml-2 mb-2">Hierbij geef ik mijn toestemming om het attest rechtstreeks over te maken aan onderstaande derde partij</span>
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="emailThirdParty"
      >
        Email derde partij
      </label>
      <input
        className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
        type="email"
        id="emailThirdParty"
        name="emailThirdParty"
        onChange={props.onChange1}
        value={props.value}
        placeholder="jane@example.com"
      />
    </div>
  </>;
}


function DeliverySpeedSelector(props: {
  onChange: (event: React.FormEvent) => void,
  selected: string
}) {
  return <div className="mt-4">
    <span className="text-gray-700 text-sm font-bold mb-2">Levering</span>
    <div className="grid grid-cols-3 gap-2 my-2 mb-4"
         onChange={props.onChange}>
      {productOptions.map(({price, text, value}) =>
        <ProductOption key={value} value={value} text={text} price={price}
                       selected={props.selected === value}/>)}
    </div>
  </div>;
}

function Billing(props: { checked: boolean, onInvoiceWantedChange: (event: React.FormEvent) => void, onBillingInfoChange: (event: React.FormEvent) => void }) {
  return <>
    <div className="my-4">
      <label className="inline-flex items-center">
        <input type="checkbox" className="form-checkbox text-green-500 h-4 w-4" checked={props.checked}
               onChange={props.onInvoiceWantedChange}/>
        <span className="ml-2">Ik wens graag een factuur</span>
      </label>
    </div>

    <div className="my-4" hidden={!props.checked}>
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="billing-info"
      >
        Facturatiegegevens
      </label>
      <textarea
        id="billing-info"
        className="h-32 rounded-lg resize-none w-full h-full bg-white focus:outline-none focus:shadow-outline border
              border-gray-300 py-2 px-4 block appearance-none leading-normal"
        onChange={props.onBillingInfoChange}/>
    </div>
  </>;
}

function SubmitButton() {
  return <button type="submit"
                 className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm
                    font-medium rounded-md text-white bg-primary-green-apple hover:bg-primary-green-olive focus:outline-none focus:ring-2
                    focus:ring-offset-2 focus:ring-primary-green-olive">
							<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-primary-green-olive group-hover:text-primary-green-apple"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fillRule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clipRule="evenodd"/>
								</svg>
							</span>
    Betaal
  </button>;
}

import React from "react";

export function Pricing() {
  const gradient = "solvattest-gradient"
  return (
    <div id="price" className="container flex flex-col items-center">
      <section
        className="flex flex-col items-center py-6 lg:pt-20 w-full lg:flex-row lg:justify-center max-w-md ">
        <PricingItem price={60.5} tierName="Standaard" style={gradient + " text-white"} primary={true}
                     benefits={["Attest afgeleverd binnen 2 werkdagen"]}/>
      </section>
    </div>
  );
}

function PricingItem(props: { tierName: string, style?: string, price: number, primary?: boolean, benefits: string[] }) {
  const {tierName, style, price, primary, benefits} = props;
  const pricingStyle = primary ? "mt-2 border-gray-100" : "border-gray-200";
  const nonPrimaryArticleStyle = "lg:w-custom rounded-lg text-center w-5/5 lg:w-4/5 mb-8 px-6 py-10 lg:px-4 bg-white text-primary-dark"
  const primaryArticleStyle = "lg:w-custom rounded-lg text-center w-5/5 lg:w-4/5 mb-8 px-6 py-16 text-white"
  const articleStyle = primary ? primaryArticleStyle : nonPrimaryArticleStyle;
  return <article className={`${articleStyle} ${style}`}>
    <h5 className="text-base font-bold">{tierName}</h5>
    <h2 className={`pb-8 flex justify-center font-bold border-b ${pricingStyle}`}>
      <span className="mt-6 mr-1 text-3xl">€</span>
      <span className="text-6xl">{price}</span>
    </h2>
    <ul className="text-sm font-bold">
      {benefits.map((benefit: string) =>
        <li className={`pt-8 pb-8`} key={benefit}>{benefit}</li>
      )}
    </ul>
  </article>;
}

import {GdwLogo} from "../components/GdwLogo";
import React from "react";

export function Offered() {
  return (
    <div className="sticky bottom-0 h-0">
      <div className="p-4 bg-primary-dark flex flex-row justify-center items-center z-50 h-60px relative -top-60px">
        <span className="text-neutral-white text-xs mr-2">Aangeboden door</span>
        <GdwLogo/>
      </div>
    </div>
  )
}

import React, {useEffect, useState} from "react";
import {Gdw} from "../components/Gdw";
import {AppConfig} from "../appConfig";
import {useLocation} from "react-router-dom";
import {Spinner} from "../components/Spinner";
import {setTimeoutPromise} from "../util/timeoutPromise";

async function getPaymentStatus(config: AppConfig, order_id: string) {
  const url = `${config.apiBaseUrl}/payment/${order_id}`;
  const response: Response = await fetch(url);
  const body = await response.json();
  return body.paymentStatus;
}

async function waitForPaymentStatus(config: AppConfig, order_id: string) {
  let result = "WAITING_FOR_PAYMENT_STATUS";
  while (result === "WAITING_FOR_PAYMENT_STATUS") {
    await setTimeoutPromise(1000)
    result = await getPaymentStatus(config, order_id);
  }
  console.log(result)
  return result;
}

export function PaymentReceived() {
  const config = AppConfig.getInstance();

  const [invalidCode, setInvalidCode] = useState(false)
  const [paymentReceived, setPaymentReceived] = useState(false)

  const order_id = new URLSearchParams(useLocation().search).get("order_id");

  useEffect(() => {
    (async () => {
      if (order_id) {
        try {
          const paymentStatus = await waitForPaymentStatus(config, order_id);
          if (paymentStatus === "paid") {
            setPaymentReceived(paymentStatus === "paid")
          } else {
            console.error(paymentStatus);
          }
        } catch (e) {
          setInvalidCode(true)
        }
      } else {
        setInvalidCode(true)
      }
    })();
  }, [order_id, config]);


  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto sm:max-w-xl">
          {(!paymentReceived && !invalidCode) &&
            <div className="text-center my-4">
              <Spinner/>
              <p className="mt-2">We controleren even of de betaling goed werd ontvangen</p>
            </div>
          }
          <div hidden={!paymentReceived}>
            <p className="text-center my-4">Bedankt!</p>
            <p className="text-center my-4">We hebben uw betaling goed ontvangen.</p>
          </div>
          <div hidden={!invalidCode}>
            <p className="text-center my-4">
              <span>Er is iets foutgelopen. Contacteer ons via </span>
              <a className="text-primary-green-olive hover:text-green-700 whitespace-nowrap"
                 href="mailto:info@gdw-gent.be">info@gdw-gent.be</a>
            </p>
          </div>
        </div>
        <p className="text-center text-primary-green-olive text-xs">
          &copy;2021 <Gdw/>. Alle rechten voorbehouden.
        </p>
      </div>
    </div>
  )
}

export function GdwLogo(props: { black?: boolean }) {
  const imageSrc = props.black ? "gdw-gent.svg" : "gdw-gent-white.svg"
  return <a href="https://www.gdw-gent.be/" target="_blank" rel="noreferrer">
    <img
      className="w-28"
      src={imageSrc}
      alt="gdw-gent logo"
    />
  </a>;
}

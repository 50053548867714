import {VraagSolvabiliteitsAttestAanButton} from "./VraagSolvabiliteitsAttestAanButton";
import {GdwLogo} from "./GdwLogo";
import {NavItem} from "./NavItem.d";
import {PageNames} from "../pages/pages";

export default function Footer() {
  const navItems: NavItem[] = [
    {label: 'Privacy Policy', link: PageNames.PRIVACY_POLICY},
    {label: 'Cookie Policy', link: PageNames.COOKIE_POLICY},
    {label: 'Algemene voorwaarden', link: PageNames.ALGEMENE_VOORWAARDEN},
  ];

  return (
    <footer id="footer" className="bg-primary-dark pt-10 pb-80px text-white text-sm">
      <div className="container">
        <div className="text-center grid grid-cols-1 justify-items-center gap-6 lg:grid-cols-12 lg:gap-0">
          <div className="flex flex-col justify-between lg:justify-self-start lg:col-span-4">
            <img
              className="mb-7 w-28"
              src="logo-white.png"
              alt="solvattest logo"
            />
            <GdwLogo/>
          </div>

          <div
            className="grid grid-cols-1 gap-2 py-1 lg:text-left lg:justify-self-center lg:col-span-4 lg:gap-x-24">

            <address className="text-center flex flex-col not-italic my-2">
              <strong>GDW-GENT</strong>
              <span>Kortrijksesteenweg 1038</span>
              <span>9051 Gent / Sint-Denijs-Westrem</span>
              <span>België</span>
              <a href="mailto:info@gdw-gent.be">info@gdw-gent.be</a>
            </address>

            <div className="text-center flex flex-col not-italic my-2">
              <span>KBO : 0808.505.886</span>
            </div>

            <a href="tel:+32475331543" className="hover:text-primary-green-apple text-center">
              <p>
                Patrick Van Overschelde
              </p>
              <p>
                +32 475 / 33 15 43
              </p>
            </a>
          </div>

          <div className="flex flex-col justify-between items-center lg:items-end lg:justify-self-end lg:col-span-4">
            <VraagSolvabiliteitsAttestAanButton/>
            <div className="mt-2">
              {navItems.map((item) =>
                <a className="hover:text-primary-green-apple block lg:text-right text-center"
                   href={item.link}
                   key={item.link}
                >
                  {item.label}
                </a>
              )}
            </div>
            <p className="text-neutral-white mt-6">
              © Solvattest. Alle rechten voorbehouden.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

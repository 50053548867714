import React from "react";

export function How() {
  return (
    <section id="how" className="py-14 bg-neutral-white lg:py-24">
      <div className="container text-center lg:text-left">
        <div className="grid mb-12 lg:grid-cols-2 lg:mb-8">
          <div className="col-span-1">
            <h2 className="pb-5 text-3xl lg:text-4xl text-primary-dark-green">
              Hoe?
            </h2>
            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base">
              Maar hoe werkt solvattest nu? Simpel!
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-9 lg:gap-6 lg:grid-cols-5">
          {motivationItems.map(({subtitle, title}) => (
            <div key={title} className="justify-center text-center bg-neutral-light-grayish-blue rounded-lg">
              <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-0 lg:text-xl lg:font-bold">
                {title}
              </h2>
              <p className="text-sm font-light leading-5 text-neutral-grayish-blue p-4">
                {subtitle}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

const motivationItems: { subtitle: string; title: string }[] = [
  {
    title: 'Stap 1',
    subtitle:
      "Klik op de knop : 'Vraag een attest aan'",
  },
  {
    title: 'Stap 2',
    subtitle:
      'Vul je e-mailadres in waarop het attest kan worden afgeleverd',
  },
  {
    title: 'Stap 3',
    subtitle:
      'Kies daarna zelf binnen welke termijn je het attest afgeleverd wenst te zien',
  },
  {
    title: 'Stap 4',
    subtitle:
      'Druk op ‘Betaal’ en voer de betaling uit',
  },
  {
    title: 'Stap 5',
    subtitle:
      'Haal je rijksregister op via itsme',
  }
];

import React from "react";
import {Gdw} from "./Gdw";

export function Who() {
  return (
    <section id="who" className="py-14 bg-neutral-light-grayish-blue lg:py-24">
      <div className="container text-center lg:text-left">
        <div className="grid mb-12 lg:grid-cols-2 lg:mb-16">
          <div className="col-span-1">
            <h2 className="pb-5 text-3xl lg:text-4xl text-primary-dark-green">
              Wie?
            </h2>
            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base">
              De gerechtsdeurwaarder is krachtens artikel 519, §2, 14° van het Gerechtelijk Wetboek bevoegd voor het
              uitvoeren van solvabiliteitsonderzoeken en voor het opstellen en afleveren van een rapport hiervan.
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mt-4">
              Solvattest is een initiatief van gerechtsdeurwaarderskantoor <Gdw/> die vervolgens instaat voor de analyse en het afleveren van het gevraagde attest.
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mt-4">
              Wanneer de aanvraag wordt ontvangen zal de gerechtsdeurwaarder je solvabiliteit analyseren op basis van
              gegevens uit het rijksregister, de Dienst voor Inschrijvingen van Voertuigen (DIV) en eigen data.
              Daarnaast zal de gerechtsdeurwaarder ook in de bestaande databank controleren of er geen (actuele)
              beslagberichten ingeschreven staan op uw naam.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import {PageNames} from "./pages";

export enum FetchDataState {
  LOADING = "LOADING",
  FAILED = "FAILED",
  SUCCESSFUL = "SUCCESSFUL",
}

export function FetchingNRNFailed(props: { state: FetchDataState }) {
  return <div hidden={props.state !== FetchDataState.FAILED}>
    <p className="text-center my-4">
      Er is iets foutgelopen bij het ophalen van jouw rijksregisternummer.
    </p>
    <p className="text-center my-4">
      <Link
        className="text-primary-green-olive hover:text-green-700"
        to={PageNames.ITSME}>
        Probeer opnieuw
      </Link>
    </p>
    <p className="text-center my-4">
      <span className="block">Of contacteer ons via</span>
      <a className="text-primary-green-olive hover:text-green-700 whitespace-nowrap"
         href="mailto:info@gdw-gent.be">info@gdw-gent.be</a>
    </p>
  </div>;
}

import React from "react";
import {Navbar} from "../components/Navbar";
import Footer from "../components/Footer";

export function PrivacyPolicy() {
  return (
    <div className="flex flex-col justify-center min-h-screen mt-32">
      <Navbar/>
      <div className="container relative min-h-screen lg:block pb-10 policy">

        <h1 id="introductie">Introductie</h1>
        <h2 id="wie-zijn-wij-">Wie zijn wij?</h2>
        <p>GDW Gent fungeert tijdens de verwerking van uw persoonsgegevens als verwerkingsverantwoordelijke wanneer u
          gebruikt
          maakt van de Website en de daarmee gerelateerde diensten. Dit betekent dat wij het doel en de middelen (of
          het &quot;waarom&quot; en &quot;hoe&quot;) van de verwerking van uw persoonsgegevens bepalen.</p>
        <h2 id="wanneer-is-deze-privacyverklaring-van-toepassing-">Wanneer is deze Privacyverklaring
          van
          toepassing?</h2>
        <p>GDW-Gent BV BVBA (hierna &quot;GDW-Gent&quot;, &quot;wij&quot; of &quot;ons&quot;) hecht veel belang aan uw
          privacy
          en vindt het belangrijk dat uw persoonsgegevens steeds met de nodige zorg en vertrouwelijkheid behandeld
          worden.</p>
        <p>Wij begrijpen dat u wil weten waarom wij uw persoonsgegevens vragen en dat u nieuwsgierig bent naar wat wij
          daar
          allemaal mee doen. Via dit privacy statement (hierna &quot;Privacy Statement&quot;) proberen wij hier alvast
          een
          antwoord op te bieden. Indien u na het lezen van dit Privacy Statement nog vragen heeft, kan u ons
          vanzelfsprekend
          steeds contacteren.</p>
        <p>Om misverstanden te vermijden, verduidelijken wij dat dit Privacy Statement van toepassing is op onze
          website <a
            href="http://www.solvattest.be/">www.solvattest.be</a> (hierna &#39;de Website&#39;) en op de diensten die
          worden
          aangeboden via de Website hierna &#39;de Diensten&#39;).</p>
        <p>Indien u middels de Website ons verzoekt een solvabiliteitsattest op te maken, zullen wij op basis van onze
          wettelijke bevoegdheden als gerechtsdeurwaarder diverse databases zoals het rijksregister of Dienst
          Inschrijving
          Voertuigen consulteren. Hiervoor zullen wij dus persoonsgegevens van u verwerken.</p>
        <p>Onder &#39;verwerking van persoonsgegevens&#39; verstaan we elke verwerking van gegevens die u als
          natuurlijke
          persoon kunnen identificeren. Over welke gegevens het dan precies gaat, leest u verder in deze
          Privacyverklaring.
          Het begrip &#39;verwerking&#39; is ruim en dekt onder andere het verzamelen, bewaren, gebruiken of verspreiden
          van
          die gegevens.</p>
        <p>Voor vragen over deze verklaring of over de verwerking van uw persoonsgegevens verwijzen wij u in eerste
          instantie
          graag door naar uw gekende contactpersoon bij ons. U kunt ons ook steeds bereiken via onderstaande
          gegevens:</p>
        <p>Per post:</p>
        <p>GDW-Gent BV BVBA</p>
        <p>t.a.v. de DPO</p>
        <p>Kortrijksesteenweg 1038</p>
        <p>9051 Gent/ Sint-Denijs-Westrem</p>
        <p>Per e-mail: </p>
        <p><a href="mailto:privacy@gdw-gent.be">privacy@gdw-gent.be</a></p>
        <h1 id="gebruik-van-persoonsgegevens">Gebruik van persoonsgegevens</h1>
        <h2 id="welke-persoonsgegevens-kunnen-wij-verzamelen-">Welke persoonsgegevens kunnen wij
          verzamelen?</h2>
        <h3>Bezoekers van de Website</h3>
        <p>Indien u onze Websites bezoekt, verwerken wij via het gebruik van cookies mogelijk onderstaande gegevens van
          u
          (zie
          onze Cookie Policy voor meer uitleg).</p>
        <ul>
          <li>Uw elektronische identificatiegegevens, zoals uw IP-adres, browsertype, verbindingsmomenten,
            voorkeurstaal,
            …
          </li>
          <li>Uw identificatiegegevens, zoals een kopie van uw identiteitskaart (enkel om indien nodig uw identiteit te
            verifiëren wanneer u één van uw rechten als betrokkene wenst uit te oefenen op basis van de Algemene
            Verordening
            Gegevensbescherming);
          </li>
          <li><strong>Uw contactgegevens, zoals</strong> uw naam, telefoonnummer, e-mailadres, … (bijvoorbeeld wanneer
            u ons deze verstrekt in het kader van de uitoefening van uw rechten);
          </li>
        </ul>
        <h3>Klanten</h3>
        <p>Wanneer u via de Website een aanvraag doet om een solvabiliteitsattest te verkrijgen, wordt een contract
          tussen
          u
          en GDW-Gent gevormd. Wij verwerken uw persoonsgegevens die noodzakelijk zijn om dit contract te kunnen
          uitvoeren
          (met name om uw solvabiliteitsattest op te kunnen maken).</p>
        <p>Voor dit doeleinde verwerken wij mogelijk de volgende gegevens van u:</p>
        <ul>
          <li><strong>Uw identificatiegegevens</strong></li>
        </ul>
        <p>Bijvoorbeeld een kopie van uw identiteitskaart (enkel om indien nodig uw identiteit te verifiëren wanneer u
          één
          van
          uw rechten als betrokkene wenst uit te oefenen op basis van de Algemene Verordening Gegevensbescherming);</p>
        <ul>
          <li>U <strong>w contactgegevens</strong></li>
        </ul>
        <p>Bijvoorbeeld uw naam, telefoonnummer, e-mailadres, adres, …</p>
        <ul>
          <li><strong>Uw financiële bijzonderheden</strong></li>
        </ul>
        <p>Bijvoorbeeld financiële identificatiegegevens zoals een bankrekeningnummer, het bestaan van actuele
          beslagberichten, informatie omtrent inkomsten, bezittingen en investeringen, informatie omtrent leningen,
          hypotheken, kredieten, …</p>
        <ul>
          <li><strong>Uw persoonlijke kenmerken</strong></li>
        </ul>
        <p>Bijvoorbeeld leeftijd, geslacht, geboortedatum, geboorteplaats, …</p>
        <ul>
          <li><strong>Uw leefgewoonten</strong></li>
        </ul>
        <p>Bijvoorbeeld uw gezinssituatie, wettelijke samenwoning, …</p>
        <ul>
          <li><strong>Uw openbare gegevens</strong></li>
        </ul>
        <p>Bijvoorbeeld publiek beschikbare gegevens zoals berichten in de pers, uw profiel op professionele netwerken,
          …</p>
        <ul>
          <li><p><strong>Uw Rijksregisternummer</strong> (alleen om u als persoon te identificeren)</p>
            <h2 id="bijzondere-categorie-n-van-persoonsgegevens">Bijzondere categorieën van
              persoonsgegevens</h2>
          </li>
        </ul>
        <p>In principe zullen wij geen bijzondere categorieën van persoonsgegevens (i.e. gegevens over raciale of
          etnische
          oorsprong, politieke meningen, religieuze of filosofische opvattingen, lidmaatschap van vakverenigingen,
          genetische
          gegevens, biometrische gegevens om een natuurlijke persoon eenduidig te identificeren, gezondheid of het
          seksleven
          of de seksuele geaardheid van een natuurlijke persoon) verwerken tenzij die ons met uw uitdrukkelijke
          toestemming
          worden overgemaakt.</p>
        <h2 id="wat-zijn-de-rechtsgronden-die-wij-gebruiken-om-uw-persoonsgegevens-te-verwerken-">Wat
          zijn de rechtsgronden die wij gebruiken om uw persoonsgegevens te verwerken?</h2>
        <p>Wij verwerken uw gegevens enkel voor legitieme doeleinden waarbij de verwerking steeds gebaseerd zal zijn op
          de
          verwerkingsgronden die worden opgesomd in de Algemene Verordening Gegevensbescherming.</p>
        <p>Wij steunen de verwerking van persoonsgegevens op de volgende gronden:</p>
        <ol>
          <li><p>De verwerking is noodzakelijk voor het aangaan, uitvoeren of beëindigen van de overeenkomst met u
            teneinde u
            onze diensten te kunnen verschaffen in het bijzonder tot het verstrekken van een solvabiliteitsattest.</p>
          </li>
          <li><p>De verwerking is noodzakelijk om te voldoen aan een wettelijke of reglementaire verplichting die op ons
            rust.
            Wij denken daarbij bijvoorbeeld aan het verwerken van uw Rijksregisternummer om uw identiteit te
            verifiëren.</p>
          </li>
          <li><p>De verwerking is gebaseerd op basis van onze gerechtvaardigde belangen die in specifieke gevallen
            zwaarder
            wegen dan enig mogelijk nadeel voor uw rechten en mits aan specifieke voorwaarden is voldaan.</p>
          </li>
          <li><p>De verwerking is gebaseerd op uw toestemming. Deze toestemming wordt afgeleid uit het vrijwillig en
            rechtstreeks verstrekken van uw gegevens aan ons, bijvoorbeeld wanneer u op de Website aangeeft dat een
            doorgifte
            van het solvabiliteitsattest naar andere partijen dan uzelf mag plaatsvinden, of voor het plaatsen van
            niet-essentiële cookies.</p>
          </li>
        </ol>
        <p>U kunt uw toestemming te allen tijde intrekken door contact met ons op te nemen. Wij vragen u om een
          telefonische
          intrekking van uw toestemming steeds schriftelijk te bevestigen, dan wel uw toestemming in te trekken per
          e-mail
          of
          per brief.</p>
        <h2 id="wat-zijn-de-doeleinden-waarvoor-wij-uw-persoonsgegevens-verwerken-">Wat zijn de
          doeleinden waarvoor wij
          uw
          persoonsgegevens verwerken?</h2>
        <p>Wij gebruiken uw persoonsgegevens voor de doeleinden die hieronder worden beschreven. We verzamelen en
          verwerken
          niet meer en geen andere soorten gegevens dan deze die nodig zijn voor deze doeleinden. Wanneer wij uw
          persoonsgegevens wensen te verwerken voor een ander doel dan dat waarvoor de persoonsgegevens zijn verkregen,
          zullen
          wij u contacteren vóór de verdere verwerking van die gegevens.</p>
        <p>Wij verzamelen uw gegevens voor de volgende te onderscheiden doeleinden:</p>
        <ol className="list-decimal	ml-4">
          <li><strong>operationele doeleinden</strong> : bijvoorbeeld de optimalisatie van onze Website, om onze
            diensten
            gebruikersvriendelijk te houden, het beantwoorden van uw contactvraag en verzoeken.
          </li>
          <li><strong>zakelijke doeleinden:</strong> bijvoorbeeld om u onze diensten aan te bieden of te verlenen, om u
            te
            informeren over ons beleid en dienstverlening, het doorgeven van gegevens van klanten aan zakenpartners om
            de
            diensten te kunnen verlenen, het factureren van de verstrekte diensten aan onze klanten, communicatie met
            onze
            medewerkers en zakenpartners omtrent de verlening van onze diensten aan u als onze klant.
          </li>
          <li><strong>veiligheidsdoeleinden:</strong> bijvoorbeeld om u te kunnen identificeren.</li>
          <li><p><strong>juridische of wettelijke doeleinden</strong> : bijvoorbeeld voor juridische redenen en
            procedures, om
            te voldoen aan wetgeving en overheidsbevelen, om te kunnen voldoen aan onze interne en externe auditeisen,
            informatieveiligheid of ter bescherming of afdwinging van onze rechten, privacy, veiligheid of eigendom of
            die
            van
            andere personen.</p>
          </li>
        </ol>
        <h2 id="hoe-lang-bewaren-wij-uw-gegevens-">Hoe lang bewaren wij uw gegevens?</h2>
        <p>GDW-Gent bewaart uw persoonsgegevens niet langer dan nodig is voor het doel waarvoor de gegevens zijn
          verzameld
          of
          worden verwerkt.</p>
        <p>Aangezien de termijn waarvoor de gegevens kunnen worden behouden afhangt van de doeleinden waarvoor de
          gegevens
          zijn verzameld, kan de opslagperiode in elke situatie variëren. Soms zal specifieke wetgeving ons verplichten
          de
          gegevens te behouden voor een bepaalde periode. Voorwaarden voor behoud/opslag die door specifieke wetgeving
          zijn
          vastgesteld, worden steeds door ons nageleefd.</p>
        <p>Wanneer het niet langer noodzakelijk is uw persoonsgegevens te verwerken, zullen we uw persoonlijke gegevens
          wissen
          of anonimiseren. Als dit niet (technisch of praktisch) mogelijk is, bijvoorbeeld omdat uw gegevens in
          back-uparchieven zijn opgeslagen of omdat wij deze beperkt nodig hebben voor de administratie waarin
          informatie
          staat over betrokkenen die niet willen dat we in de toekomst opnieuw contact met hen opnemen, dan bewaren wij
          uw
          gegevens maar zullen wij deze niet verder verwerken en verwijderen van zodra dit mogelijk wordt.</p>
        <h2 id="van-wie-ontvangen-wij-uw-persoonsgegevens-">Van wie ontvangen wij uw
          persoonsgegevens?</h2>
        <p>Wij kunnen persoonsgegevens verzamelen door het gebruik van onze eigen systemen of door het verkrijgen van u
          rechtstreeks, van openbare bronnen of van zakenpartners/derden. Het opmaken van een solvabiliteitsattest valt
          onder
          de wettelijke taken die aan een gerechtsdeurwaarder zijn toevertrouwd. GDW-Gent heeft voor het opmaken van het
          solvabiliteitsattest bijgevolg o.m. toegang tot de volgende authentieke bronnen:</p>
        <ul>
          <li>Het Rijksregister van natuurlijke personen;</li>
          <li>Het Register van de Dienst Inschrijving Voertuigen</li>
          <li>Centraal Bestand van de Berichten van het Beslag</li>
        </ul>
        <p>Sommige gegevens worden daarenboven op geautomatiseerde wijze verzameld door middel van het gebruik van
          cookies
          of
          daarmee vergelijkbare technologieën. Voor meer uitleg hierover verwijzen wij naar onze Cookieverklaring.</p>
        <h2 id="hoe-beveiligen-wij-uw-persoonsgegevens-">Hoe beveiligen wij uw persoonsgegevens?</h2>
        <p>Wij hechten veel belang aan gegevensbeveiliging en hebben de nodige fysieke en passende technische en
          organisatorische (voorzorg)maatregelen getroffen met het oog op het beveiligen van uw persoonsgegevens tegen
          verlies
          of enige vorm van onrechtmatige verwerking.</p>
        <p>In het geval van een inbreuk in verband met persoonsgegevens zullen wij alle toepasselijke meldplichten met
          betrekking tot dergelijk inbreuk opvolgen.</p>
        <h2 id="waar-bewaren-wij-uw-persoonsgegevens-">Waar bewaren wij uw persoonsgegevens?</h2>
        <p>Wij bewaren uw persoonsgegevens voornamelijk op IT-systemen in België. Het beheer van sommige van onze
          IT-systemen
          wordt echter uitbesteed aan derden waarbij de gegevens worden bewaard in de Europese Economische Ruimte (EER).
          Wanneer wij IT-dienstverleners of andere zakenpartners ingeschakeld hebben die zich buiten de EER bevinden,
          wat
          betekent dat uw gegevens ook buiten de bovengenoemde landen kunnen worden opgeslagen, zullen wij steeds
          voldoen
          aan
          de vereisten die de Algemene Verordening Gegevensbescherming stelt om de adequate bescherming van uw gegevens
          in
          deze derde landen te verzekeren.</p>
        <h2 id="met-wie-delen-wij-gegevens-">Met wie delen wij gegevens?</h2>
        <p>In principe zullen wij uw persoonsgegevens niet openbaar maken aan derden, tenzij wanneer wij daar door de
          wet
          toe
          verplicht zijn of wanneer het noodzakelijk is in het kader van het verlenen van onze diensten en de
          optimalisering
          daarvan. Zo kunnen uw persoonsgegevens meegedeeld worden medewerkers of leveranciers van GDW-Gent.</p>
        <p>GDW-Gent kan gegevens ook doorgeven aan derde partijen zoals banken, kandidaat-verhuurders en mogelijke
          zakelijke
          partners van u, maar tenzij de wet ons hiertoe verplicht, alleen wanneer u ons hiervoor uw toestemming heeft
          gegeven
          op de Website (bijvoorbeeld door hun mailadres voor doorgifte op te geven).</p>
        <p>In geen geval zullen wij uw persoonsgegevens niet doorverkopen of verhuren of doorgeven aan derden, tenzij
          wanneer
          wij hiervoor van u uw uitdrukkelijke toestemming hebben bekomen.</p>
        <p>Waar nodig doen wij een beroep op externe dienstverleners, zogenaamde &quot;verwerkers&quot;, die
          desgevallend
          bepaalde verwerkingen van persoonsgegevens uitvoeren namens ons. Wij zullen uw persoonsgegevens slechts delen
          met
          deze externe dienstverleners in de mate dat dit nodig is voor het desbetreffende doel. De gegevens mogen door
          hen
          niet voor andere doeleinden worden gebruikt. Bovendien zijn deze dienstverleners contractueel gebonden om de
          vertrouwelijkheid van uw persoonsgegevens te waarborgen door middel van een
          zogenaamde &quot;verwerkersovereenkomst&quot; die met deze partijen werd afgesloten.</p>
        <p>Concreet heeft dit tot gevolg dat wij uw gegevens, voor zover nodig zoals hieronder gespecifieerd, met de
          volgende
          derden delen voor de volgende doeleinden, waarbij deze derden in bepaalde gevallen optreden als verwerkers
          namens
          ons:</p>
        <ul>
          <li>Molli treedt voor de betalingen op de Website op als betalingsdienstverlener;</li>
          <li>ItsMe wordt gebruikt om de gebruiker van de Website bij een aanvraag te identificeren;</li>
          <li>de verwerkers die ons op IT-gebied bijstaan bij het uitvoeren van onze activiteiten, met het oog op een
            efficiënt digitaal gegevensbeheer, waaronder het bewaren van onze bestanden aan klanten en zakenpartners,
            het
            hosten en beheren van onze Websites, mailboxen, het ons verlenen van inzicht in de manier waarop u onze
            Websites
            gebruikt door middel van de diensten aangeboden door Google Analytics, etc.;
          </li>
          <li>overheidsorganen en beoefenaars van gereglementeerde beroepen zoals boekhouders en advocaten, met het oog
            op
            de
            naleving van onze wettelijke verplichtingen als onderneming, en de efficiënte verdediging van onze belangen
            in
            het
            kader van een eventueel juridisch geschil, voor wat betreft de gegevens die hier strikt noodzakelijk voor
            zijn.
          </li>
        </ul>
        <p>De doorgiftes van uw gegevens aan de hierboven geïdentificeerde partijen voor de vermelde doeleinden is
          naargelang
          het geval gebaseerd op (1) de noodzaak hiervan voor de uitvoering van de overeenkomst die wij met u hebben als
          onze
          klant, kandidaat of zakenpartner, of om op uw verzoek maatregelen te nemen vóór de sluiting van dergelijke
          overeenkomst, (2) onze gerechtvaardigde belangen, (3) de noodzaak hiervan om te voldoen aan een wettelijke
          verplichting die op ons rust, of (4) uw toestemming.</p>
        <h2 id="welke-gegevens-geven-wij-door-aan-derde-landen-">Welke gegevens geven wij door aan derde
          landen?</h2>
        <p>GDW-Gent geeft op geen enkel moment persoonsgegevens van u door aan derden buiten de EER.</p>
        <h1 id="rechten-van-de-betrokkene">Rechten van de betrokkene</h1>
        <p>Als betrokkene beschikt u over diverse rechten met betrekking tot de over u verzamelde persoonsgegevens.
          Indien
          u
          beroep wenst te doen op één van de hiernavolgende rechten, gelieve ons te contacteren via voorgemelde
          contactgegevens. U beschikt over de volgende rechten:</p>
        <ul>
          <li><p>Recht van inzage en kopie, waardoor u het recht heeft om ons te vragen om u informatie te verstrekken
            over de
            persoonsgegevens die wij over u hebben;</p>
          </li>
          <li><p>Recht van aanpassing of rectificatie, waardoor u her recht heeft om ons te vragen dat wij
            persoonsgegevens
            corrigeren indien u kunt aantonen dat de persoonsgegevens die wij over u houden onjuist, onvolledig of
            verouderd
            zijn;</p>
          </li>
          <li><p>Recht van gegevensuitwissing (recht op vergetelheid), waardoor u ons kan vragen om uw gegevens te
            verwijderen
            als deze persoonsgegevens niet langer nodig zijn voor de doeleinden waarvoor wij ze hebben verzameld, als
            het
            verzamelen ervan onwettig was of als u met succes gebruik hebt gemaakt van uw recht om uw toestemming in te
            trekken of uw recht om bezwaar te maken tegen de verwerking van uw persoonsgegevens;</p>
          </li>
          <li><p>Recht op beperking van verwerking, waardoor u kan vragen om de verwerking van u gegevens tijdelijk te
            onderbreken terwijl wij een uitoefening van het recht op bezwaar of het recht op rectificatie
            beoordelen;</p>
          </li>
          <li><p>Recht van bezwaar, waardoor u het recht heeft om zich te verzetten tegen een verwerking wanneer deze
            verwerking gebeurt op basis van onze eigen belangen. Als deze belangen andere belangen zijn dan direct
            marketing
            (vb. in het geval van veiligheidsbelangen), dan vragen wij u om de specifieke omstandigheden te beschrijven
            die
            aanleiding geven tot de uitoefening van het recht. Wij zullen dan uw belangen afwegen tegen onze belangen en
            indien uw belangen in de concrete omstandigheden zwaarder wegen dan onze belangen, zullen wij de verwerking
            staken;</p>
          </li>
          <li><p>Recht op overdraagbaarheid, waardoor u wanneer de verwerking van persoonsgegevens gebeurt op basis van
            uw
            toestemming of voor de uitvoering van de overeenkomst, ons kan vragen om u een kopie van deze gegevens te
            bezorgen
            in een gestructureerd, machine-leesbaar formaat;</p>
          </li>
        </ul>
        <p>De uitoefening van bovenstaande rechten is echter onderworpen aan bepaalde uitzonderingen om het algemeen
          belang,
          onze belangen en belangen van andere individuen te beschermen.</p>
        <p>Wanneer u een verzoek tot uitoefening van uw rechten indient zal GDW-Gent eerst en vooral uw identiteit
          verifiëren
          door middel van het opvragen van een kopij van uw identiteitskaart. Dit doen we om te voorkomen dat uw
          gegevens
          in
          de verkeerde handen terechtkomen.</p>
        <p>De uitoefening van uw rechten is in principe kosteloos. Wanneer uw verzoek kennelijk ongegrond of
          buitensporig
          is,
          kunnen wij u een redelijke vergoeding aanrekenen in het licht van de door ons gemaakte administratieve kosten.
          In
          datzelfde geval kunnen wij er echter ook voor kiezen om geen gevolg te geven aan uw verzoek. U wordt
          desgevallend
          steeds op de hoogte gebracht van de redenen hiervoor.</p>
        <p>In ieder geval brengen wij u steeds uiterlijk binnen een termijn van vier weken (bij eenvoudige verzoeken)
          dan
          wel
          3 maanden (bij complexe of veelvoudige verzoeken) op de hoogte van het gevolg dat aan uw verzoek wordt
          gegeven.</p>
        <h1 id="klachtmogelijkheden-van-de-betrokkene">Klachtmogelijkheden van de betrokkene</h1>
        <p>Ondanks alles dat wij in het werk stellen om uw privacy te beschermen en de wetgeving daaromtrent na te
          leven,
          kan
          het zijn dat u het niet eens bent met de manier waarop GDW-Gent uw persoonsgegevens verzamelt, gebruikt en/of
          verwerkt.</p>
        <p>Vanzelfsprekend kan u in dat geval steeds contact met ons opnemen maar daarnaast beschikt u ook over andere
          klachtmogelijkheden.</p>
        <p>In de eerste plaats kunt u een klacht indienen bij de toezichthoudende overheid, die u via onderstaande
          gegevens
          kan contacteren:</p>
        <p>Gegevensbeschermingsautoriteit:</p>
        <p>Drukpersstraat 35 1000 BRUSSEL</p>
        <p>Tel: +32 (0)2 274 48 00</p>
        <p>Fax: +32 (0)2 274 48 35</p>
        <p>E-mail:
          <a href="mailto:contact@apd-gba.be"> contact@apd-gba.be</a></p>
        <p>In de tweede plaats, indien u schade zou lijden, kan u daarnaast ook een vordering tot schadevergoeding
          instellen
          bij de bevoegde rechtbank.</p>
        <p>Voor meer informatie met betrekking tot klachten en mogelijkheden tot verhaal nodigen wij u uit om het
          volgende
          adres van de Gegevensbeschermingsautoriteit te raadplegen: <a
            href="http://www.privacycommission.be/nl/klacht-en-beroep">www.privacycommission.be/nl/klacht-en-beroep</a>.
        </p>
        <h1 id="wijzigingen">Wijzigingen</h1>
        <p>Wij kunnen eenzijdig beslissen om veranderingen aan deze Privacyverklaring aan te brengen. In dat geval
          treedt
          de
          nieuwe versie onmiddellijk in werking. De meest recente versie is te allen tijde beschikbaar op onze Websites.
          In
          geval van een substantiële wijziging van deze Privacyverklaring die relevant is voor u, zullen wij u – voor
          zover
          mogelijk – hierover rechtstreeks informeren. De datum waarop deze Privacyverklaring voor het laatst gewijzigd
          is
          geweest, is onderaan terug te vinden.</p>
        <p>Laatst gewijzigd op: 01/10/2021</p>
      </div>
      <Footer/>
    </div>
  );
}

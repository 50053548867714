import React from "react";
import {Pricing} from "../components/Pricing";
import Footer from "../components/Footer";
import {Hero} from "../components/Hero";
import {What} from "../components/What";
import {Navbar} from "../components/Navbar";
import {How} from "../components/How";
import {Who} from "../components/Who";

export function Home() {
  return (
    <div className="flex flex-col justify-center min-h-screen text-white">
      <Navbar/>
      <div className="overflow-hidden relative">
        <Hero/>
        <What/>
        <How/>
        <Who/>
      </div>
      <Pricing/>
      <Footer/>
    </div>
  )
}
